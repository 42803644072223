<template>
  <b-overlay :show="showLoading">
    <b-row v-if="staticPage">

      <b-col cols="12">
        <b-card class="">
          <h4>بنر صفحه {{ staticPage.title }}</h4>
            <b-row class="mt-2">
              <b-col cols="12">
                <quill-editor
                    v-model="staticPage.content"></quill-editor>
              </b-col>
              <b-col cols="12">
                <b-button
                    variant="primary"
                    class="mt-2 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="setStaticPage"
                >
                  ثبت
                </b-button>
              </b-col>
            </b-row>


        </b-card>
      </b-col>


    </b-row>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BOverlay,BFormTextarea,BFormGroup
} from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

import {PostCreateRequest} from "@/libs/Api/Post";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {StaticPageGetByTypeRequest, StaticPageUpdateRequest} from "@/libs/Api/StaticPage";
import {AccountGetSliderRequest, AccountSetSliderRequest} from "@/libs/Api/Account";
export default {
  name: "aboutUs",
  title: 'ویرایش صفحه شرکت در دوره حضوری - پنل ادمین مکس ',
  data(){
    return{
      staticPage:null,
      showLoading:false,
    }
  },
  async created(){
    await this.getStaticPage();
  },
  methods:{
    async getStaticPage(){
      let _this = this;
      _this.showLoading = true;
      let staticPageGetByTypeRequest = new StaticPageGetByTypeRequest(_this);
      staticPageGetByTypeRequest.setParams({type:1});
      await staticPageGetByTypeRequest.fetch(function (content){
        _this.showLoading = false;
        _this.staticPage = content[0];
      },function (error){
        _this.showLoading = false;
        console.log(error)
      })
    },
    async setStaticPage(param){
      let _this = this;
      _this.showLoading=true;
      let staticPageUpdateRequest = new StaticPageUpdateRequest(_this);
      staticPageUpdateRequest.setParams(_this.staticPage);
      await staticPageUpdateRequest.fetch(function (content){
        _this.showLoading=false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        _this.getStaticPage();
      },function (error){
        _this.showLoading=false;
        console.log(error)
      })
    },

  },
  components:{
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    quillEditor,
    BFormTextarea,
    BFormGroup
  },
}
</script>

<style scoped>

</style>
